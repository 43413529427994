// import en from "./en.json";
// import de from "./de.json";

import VueI18n from "vue-i18n";
import Vue from "vue";
// import store from "../store";

Vue.use(VueI18n);

import en from "../translations/en.json";
import de from "../translations/de.json";
import nl from "../translations/nl.json";
import cs from "../translations/cs.json";
import da from "../translations/da.json";
import es from "../translations/es.json";
import fi from "../translations/fi.json";
import fr from "../translations/fr.json";
import hu from "../translations/hu.json";
import it from "../translations/it.json";
import pl from "../translations/pl.json";
import sv from "../translations/sv.json";
import bg from "../translations/bg.json";
import ee from "../translations/ee.json";
import el from "../translations/el.json";
import hr from "../translations/hr.json";
import is from "../translations/is.json";
import lt from "../translations/lt.json";
import lu from "../translations/lu.json";
import lv from "../translations/lv.json";
import mt from "../translations/mt.json";
import no from "../translations/no.json";
import pt from "../translations/pt.json";
import ro from "../translations/ro.json";
import ru from "../translations/ru.json";
import sk from "../translations/sk.json";
import sl from "../translations/sl.json";
import tr from "../translations/tr.json";
import uk from "../translations/uk.json";

export const languages = {
  gb: en,
  en: en,
  de: de,
  at: de,
  nl: nl,
  ie: en,
  cz: cs,
  dk: da,
  es: es,
  fi: fi,
  fr: fr,
  hu: hu,
  it: it,
  pl: pl,
  se: sv,
  bg: bg,
  ee: ee,
  gr: el,
  hr: hr,
  is: is,
  lt: lt,
  lu: lu,
  lv: lv,
  mt: mt,
  no: no,
  pt: pt,
  ro: ro,
  ru: ru,
  sk: sk,
  si: sl,
  tr: tr,
  ua: uk,
  cy: el,
};
export const defaultLocale = "gb";
const messages = Object.assign(languages);

const i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
  messages,
});

export default i18n;
