<template>
  <!-- <div> -->
  <div
    class="homePageWrapper"
    ref="homePageWrapper"
    :style="{
      height: homePageWrapperHeight,
    }"
    v-if="!isCountryWindowOpen && !isSelectMethodWindowOpen"
  >
    <header>
      <div class="homeLogoWrapper">
        <img src="/img/icons/home-logo.svg" loading="lazy" alt="home-logo" />
        <span class="homeLogoWrapper__text">
          <p class="homeLogoWrapper__text-top">your size</p>
          <!-- <br /> -->
          <p class="homeLogoWrapper__text-bottom">pure feel</p>
        </span>
      </div>
    </header>
    <main>
      <section class="mainTextSection">
        <h1>{{ $t("main_slogan") }}</h1>
        <p>
          {{ $t("home_slogan") }}
        </p>
      </section>
      <section>
        <main-button
          class="homeBtn selectCountry"
          :text="`${$t('home_button_text')}`"
          :href="'/country-select'"
          @click="countryWindowOpenToggle"
          ><template #playLogo>
            <img
              src="/img/icons/Vectorplay.svg"
              loading="lazy"
              alt="card logo" /></template
        ></main-button>
      </section>
    </main>
    <footer>
      <div class="footerTopWrapper">
        <a :href="imprintLink" target="_blank" id="imprint">{{
          $t("home_imprint")
        }}</a>
        <a :href="PrivacyPolicyLink" target="_blank" id="privacyPolicy">{{
          $t("home_privacy_policy")
        }}</a>
      </div>
      <div class="footerBottomWrapper">
        <p id="copyRights">© 2022 MISTER SIZE</p>
      </div>
    </footer>
  </div>
  <!-- <div v-else-if="!isSelectMethodWindowOpen">
      <country-select-window />
    </div> -->
  <!-- </div> -->
</template>

<script>
export default {
  name: "HomeView",
  components: {
    // countrySelectWindow: () => import("@/views/countrySelectWindow.vue"),
    mainButton: () => import("@/components/mainButton.vue"),
  },
  data() {
    return {
      homePageWrapperHeight: "100vh",
      selectedCountry: {},
      isCountryWindowOpen: false,
      isSelectMethodWindowOpen: false,
    };
  },
  async created() {},
  mounted() {
    this.setHomeViewHeight();
  },
  methods: {
    setHomeViewHeight() {
      let height = this.$refs.homePageWrapper.scrollHeight;
      if (height < 812) {
        height = 812;
      }
      this.homePageWrapperHeight = height + "px";
    },
    countryWindowOpenToggle() {
      this.isCountryWindowOpen = true;
      this.$router.push({
        name: "country-select",
      });
    },
    selectMethodWindowToggle() {
      if (this.selectedCountry.code) {
        this.isSelectMethodWindowOpen = true;
      }
    },
    // userCountrySelect(val) {
    //   if (val) {
    //     this.selectedCountry = val;
    //     this.isCountryWindowOpen = false;
    //   }
    // },
  },
  computed: {
    PrivacyPolicyLink() {
      return this.$i18n?.locale === "de"
        ? "https://www.mistersize.de/datenschutz"
        : "https://www.mistersize.co.uk/privacy-policy";
    },
    imprintLink() {
      return this.$i18n?.locale === "de"
        ? "https://www.mistersize.de/impressum"
        : "https://www.mistersize.co.uk/imprint";
    },
  },
};
</script>
<style lang="scss">
@mixin link_default {
  text-decoration: none;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #000032;
}
.homePageWrapper {
  display: grid;
  justify-content: center;
  margin: 0 auto;
  max-width: 425px;
  background-color: #dde8f2cc;
  background-image: url("../../public/img/bg-images/home_bg_img.png");
  background-size: cover;
  background-repeat: no-repeat;
}
header {
  margin-bottom: 30px;
  .homeLogoWrapper {
    position: relative;
    &__text {
      width: 120px;
      padding: 0 16px;
      position: absolute;
      display: flex;
      flex-direction: column;
      top: 200px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
      p {
        font-family: "Styler";
        word-spacing: -7px;
        color: #fff;
        font-size: 33px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        text-align: center;
      }
      &-top {
        align-self: flex-start;
      }
      &-bottom {
        align-self: flex-end;
      }
    }
  }
}

main {
  margin-bottom: 150px;
}
.mainTextSection {
  margin-bottom: 32px;
  display: grid;
  justify-content: center;
  align-items: center;
  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    text-align: center;
  }
  p {
    max-width: 295px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
  }
}
.homeBtn {
  cursor: pointer;
  border-radius: 2px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  width: 300px;
  // height: 40px;
  &.selectCountry {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ed6b6a;
    margin-bottom: 16px;
    padding: 9px 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    // &::after {
    //   display: flex;
    //   margin-left: 8px;
    //   margin-top: 5px;
    //   fill: #ffffff;
    //   align-items: center;
    //   content: url("../../public/img/icons/Vectorplay.svg");
    // }
  }
  &.start {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    // background-color: none;
    background: #ed6b6a;
    padding: 9px 131px;
  }
}
footer {
  display: grid;
  grid-template-areas:
    "footerTopWrapper"
    "footerBottomWrapper";
  justify-content: center;
  align-items: center;
  margin-bottom: 11px;

  p {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #000032;
  }

  .footerTopWrapper {
    grid-area: footerTopWrapper;
    display: grid;
    // gap: 0px 24px;
    margin-bottom: 6px;
    grid-auto-flow: row;

    #imprint {
      grid-area: imprint;
      align-self: end;
      @include link_default;
      margin-right: 24px;
    }

    #privacyPolicy {
      grid-area: privacyPolicy;
      @include link_default;
    }
    grid-template-areas: "imprint privacyPolicy";
  }

  .footerBottomWrapper {
    grid-area: footerBottomWrapper;
    display: grid;
    align-items: center;
    justify-content: center;
  }
}
</style>
