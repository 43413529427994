import apiRequest from "./axios";

export const getCountries = () => {
  return apiRequest.get("/get-countries");
};
export const getSizes = () => {
  return apiRequest.get("/get-sizes");
};
export const getCommonSizes = () => {
  return apiRequest.get("/get-common-sizes");
};
export const getProduct = (countryId, sizeId) => {
  return apiRequest.get(
    `/get-products?country_id=${countryId}&size_id=${sizeId}`
  );
};
export const getCommonProduct = (countryId, sizeId) => {
  return apiRequest.get(
    `/get-products?country_id=${countryId}&common_size_id=${sizeId}`
  );
};
export const createNewUser = (payload) => {
  return apiRequest.post("/create-user", { ...payload });
};
export const getClientIpAddress = () => {
  return apiRequest.get("/client-ip-addr");
};
