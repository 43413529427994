<template>
  <div id="app">
    <router-view v-if="!lockScreen && showMainContent" />
    <orientation-block v-else-if="lockScreen && showMainContent" />
    <desktop-block v-else />
  </div>
</template>
<script>
import { getCountries } from "./requests.js";
export default {
  components: {
    orientationBlock: () => import("@/components/orientationBlock.vue"),
    desktopBlock: () => import("@/components/desktopBlock.vue"),
  },
  data() {
    return {
      lockScreen: false,
      verticalScreen: false,
      lang: JSON.parse(window.localStorage.getItem("country"))?.code || "",
    };
  },
  created() {
    window.addEventListener("resize", this.displayOrientationChange);

    if (!this.lang) {
      this.lang = navigator.language.toLowerCase().slice(0, 2);
    }
    if (this.lang) {
      this.$i18n.locale = this.lang;
    }
  },
  watch: {
    $route: {
      immediate: true,
      async handler() {
        if (this.$route.params.code) {
          const countriesList = await this.getCountriesList();
          const country = countriesList.find(
            (el) => el.code === this.$route.params.code
          );
          if (country) {
            this.lang = country.code;
            this.$i18n.locale = this.lang;
            window.localStorage.setItem("country", JSON.stringify(country));
          }
        }
        this.displayOrientationChange();
      },
    },
  },
  methods: {
    async getCountriesList() {
      let localStorageCountriesList = JSON.parse(
        window.localStorage.getItem("countriesList")
      );
      if (!localStorageCountriesList?.length) {
        localStorageCountriesList = await getCountries().then(
          (resp) => resp.data
        );
        window.localStorage.setItem(
          "countriesList",
          JSON.stringify(localStorageCountriesList)
        );
      }
      return localStorageCountriesList;
    },
    displayOrientationChange() {
      // if (this.$route.name !== "" && !this.$isMobile()) {
      //   this.lockScreen = false;
      //   return;
      // }
      if (!this.$isMobile()) {
        this.lockScreen = false;
        return;
      }
      this.lockScreen = window.innerWidth >= window.innerHeight;
    },
  },
  computed: {
    showMainContent() {
      if (
        (this.$route.name === "grid-method" ||
          this.$route.name === "grid-method-example" ||
          this.$route.name === "comparing-card-example" ||
          this.$route.name === "calibrate") &&
        !this.$isMobile()
      ) {
        return false;
      }
      return true;
    },
  },
};
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
  font-family: "Nunito Sans";
  // touch-action: none;
  // touch-action: pan-x pan-y;
}
@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  src: url("@/assets/fonts/nunito-sans-v12-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("@/assets/fonts/nunito-sans-v12-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* Super Modern Browsers */
      url("@/assets/fonts/nunito-sans-v12-latin-regular.woff") format("woff"),
    /* Modern Browsers */
      url("@/assets/fonts/nunito-sans-v12-latin-regular.ttf") format("truetype"),
    /* Safari, Android, iOS */
      url("@/assets/fonts/nunito-sans-v12-latin-regular.svg#NunitoSans")
      format("svg"); /* Legacy iOS */
  font-display: swap;
}
@font-face {
  font-family: "Styler";
  src: url("@/assets/fonts/Styler.woff?#iefix") format("woff"),
    url("@/assets/fonts/Styler.woff2?#iefix") format("woff2");
}
#app {
  height: 0px;
  font-family: "Nunito Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
