/* eslint-disable prettier/prettier */
import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/country-select",
    name: "country-select",
    component: () => import(/* webpackChunkName: "country-select" */ "../views/countrySelectWindow.vue"),
  },
  {
    path: "/:code/grid-method-example",
    name: "grid-method-example",
    component: () => import(/* webpackChunkName: "grid-method-example" */ "../components/gridMethodExample.vue"),
  },
  {
    path: "/:code/comparing-card-example",
    name: "comparing-card-example",
    component: () => import(/* webpackChunkName: "comparing-card-example" */ "../components/comparingCardExample.vue"),
  },
  {
    path: "/:code/method-select",
    name: "method-select",
    component: () => import(/* webpackChunkName: "method-select" */ "../views/selectMethodWindow.vue"),
  },
  {
    path: "/:code/common-method",
    name: "common-method",
    component: () => import(/* webpackChunkName: "common-method" */ "../components/commonMethodSelection.vue"),
  },
  {
    path: "/:code/grid-method",
    name: "grid-method",
    component: () => import(/* webpackChunkName: "grid-method" */ "../components/gridMethodSelection.vue"),
  },
  {
    path: "/:code/calibrate",
    name: "calibrate",
    component: () => import(/* webpackChunkName: "calibrate" */ "../components/comparingCardWindow.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
