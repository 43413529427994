import Vue from "vue";

import "./registerServiceWorker";
import i18n from "./plugins/i18n";
import router from "./router";
// import store from "./store";
import { VueHammer } from "vue2-hammer";
import VueMobileDetection from "vue-mobile-detection";
import App from "./App.vue";

Vue.use(VueMobileDetection);

Vue.use(VueHammer);

Vue.config.productionTip = false;

new Vue({
  router,
  // store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
